@font-face {
  font-family: 'Helvetica Light';
  src: url("../fonts/Helvetica-Light.ttf") format('truetype');
  font-display: optional;
}

#app {

  font-family: 'Helvetica Light', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  button {
    height: 100%;
    width: 100%;
    padding:10px;
    text-decoration:none;
    color: white;
    background-color: rgb(21,106,158);
    position: relative;
    vertical-align: middle;
    line-height: 1.718em;
    transition: 0.3s cubic-bezier(0.25,0.8,0.5,1);
    box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);
    outline-color: #e4007b;
    border-radius: 3px;
    font-weight: 600;
  }

  .vue-dialog-button {
    background-color: white;
    color: black;
    border-radius: 0;
    font-weight: 400;
    box-shadow: 0 0 0 0;
  }

  .href {
    color: #156A9E;
    text-decoration: underline;
  }

  .viewport {
    position: relative;
  }

  .viewport canvas,
  .viewport video {
    position: unset;
    max-width: 100%;
    width: 100%;
  }

  .viewport canvas.drawingBuffer{
    position: absolute;
    left: 0;
    top: 0;
  }

  .statusDot {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
  }

  input {
    outline-color: #e4007b;
    outline-width: medium;
    border: 1px solid black;
    padding-left: 5px;
    width: 100%;
  }

  select {
    outline-color: #e4007b;
    outline-width: medium;
    border: 1px solid black;
    padding-left: 5px;
    width: 100%;
  }

  .back-button {
    cursor: pointer;
  }

  .scan-modal.emptybox-success {
    text-align: center;
    display: grid;

    div:first-child {
      font-weight: bold;
      padding-bottom: 5px;
    }
  }

  .clearitems-modal {
    text-align: -webkit-center;
  }

  .login {

    .card-body {

      .container {
        border: 1px solid rgb(229, 229, 229);
        border-radius: 15px;
        background-color: #23538f;
        margin-top: 30px;
        margin-bottom: 30px;
        opacity: 0.9;
        width: 30%;
      }

    }

    .row:first-child {
      margin-top: 25px;

      input {
        margin-bottom: 10px;
      }
    }

    .btn-primary {
      color: #000;
      background-color: #ffffff;
      border-color: #000000;
      width: 100%;
      height: unset;
    }

    .btn.btn-primary {
      margin-bottom: 25px;
    }

    input {
      background: #f3faf9 !important;
    }
  }

  #scanlist {

    .row {
      border-bottom: 1px solid #e5e5e5;
      padding: 10px 0 10px 0;

      .scan {
        height: 80px;
      }

      .scantable {
        height: 150px;

        .fixed_header {
          height: 100%;
        }
      }

      .sendbutton {
        height: 50px;
      }

      #barcodeCam {
        outline: none;
      }
    }

    .row:last-child {
      border-bottom: 0;
      padding: 10px 0 0 0;
    }
  }

  #voss-footer {
    margin-bottom: 10px;

    .container {

      .row {
        margin-top: 20px;
      }

      .imprint {
        width: 50%;
        text-align: right;
        padding-right: 10px;
      }

      .policy {
        width: 50%;
        text-align: left;
        padding-left: 10px;
      }

      a {
        text-decoration: underline;
        cursor: pointer;
        font-size: 15px;
      }
    }
  }

  @media (max-width: 450px) {
    /* mobile Geräte */
    #voss-menu {
      background-image: url("../img/voss-background.png");
      background-color: #cccccc;
      height: 450px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
    }

    .login {
      .card-body {
        background-image: url("../img/voss-background.png");
        background-color: #cccccc;
        height: 400px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        .container {
          width: 80%;
        }

      }
    }


  }

  #voss-menu {

    .container {

      .menu.col {
        .row:first-child {
          margin-top: 40px;
        }
        .row {
          margin-bottom: 5px;
          height: 100px;
          button {
            width: 80%;
            height: 80%;
          }
        }
      }
    }
  }

  #home {

    .container {

      .col {
        border-bottom: 1px solid #e5e5e5;
        padding-left: 0;
        padding-right: 0;
      }

      .row {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: unset;
        margin-right: unset;

        .contact{
          border: 1px solid #e5e5e5;
          margin: 5px 0 5px 0;

          .company-contact{
            margin: 10px 0 5px 0;
            font-size: 0.8rem;
            word-wrap: break-word;
            :first-child{
              font-size: 0.9rem;
            }
          }
        }

        .contact-button{
          width: 50px;
          height: 50px;
          margin: 5px 0 5px 0;
        }

        #scantime-time{
          font-size: smaller;
        }

        #scantime-position{
          position: absolute;
          bottom: 0;
        }

        .contact{
          text-align: left;
        }

        .menu{
          height: 100px;

          button{
            width: 90%;
            height: 100%;
          }
        }
      }
    }
  }

  #header{
    border-bottom:1px solid #e5e5e5;
    box-shadow:0 1px 1px #e5e5e5;
    margin:3px 0 10px 0;
    padding:0 0 6px 0;

    .navbar{
      display: flex;
      margin:0; padding:0;

      #store-logo{
        display: flex;

        img{
          height:50px;
        }
      }

      #nav {
        flex-direction: row;
        justify-content: flex-end;
        a {
          font-weight: bold;
          color: #2c3e50;
          margin-right:4px;
          &.router-link-exact-active {
            color: #42b983;
          }
        }
      }
      .LanguageSwitcher{
        flex-direction: row;
        justify-content: flex-end;
        border:1px solid #000000;
        width: 20%;
      }
    }

    .user_information{
      text-align: left;
      padding: 3px;
      font-size: 12px;
      width: 100%;
      margin: 2.5px 0 2.5px 0;
      border:1px solid #e5e5e5;
      span{
        display: block;
        label{
          padding:0;
          margin:0;
          font-weight:bold;
        }
      }
    }

  }


}
